import axios from 'axios';
import { CultureHelper, IdentityHelper } from './common/utils/utils';

const getConfig = () => {
  const token = IdentityHelper.token;
  return {
    baseURL: process.env.REACT_APP_SERVICE_BASE_URL,
    headers: {
      Authorization: `Bearer ${token ? token : ''}`,
      'Accept-Language':
        CultureHelper.language || process.env.REACT_APP_DEFAULT_LANGIAGE,
    },
    // for session cookie
    withCredentials: true,
  };
};

axios.interceptors.request.use((config) => {
  Object.assign(config, getConfig());
  return config;
});

axios.interceptors.response.use(
  async (response) => {
    if (IdentityHelper.token && IdentityHelper.shouldRefresh()) {
      axios.get("Authentication/RefreshToken").then((result) => {
        IdentityHelper.token = result.obj;
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + result.obj;
      })
    }
    return response.data
  },
  (error) => {
    if (error?.response?.status === 401 || error?.response?.status === 403) {
      IdentityHelper.removeToken();
      window.location.reload();
      return Promise.reject(error);
    }
    console.error(error);
    return Promise.resolve({ code: 0, strMessage: '' });
  }
);
