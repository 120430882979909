import { Component } from 'react';
import { Link } from 'react-router-dom';
import { toAbsoluteUrl } from '../theme/helpers';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.error(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className='d-flex flex-column flex-root'>
          <div className='d-flex flex-column flex-center flex-column-fluid p-4'>
            <img
              src={toAbsoluteUrl('/media/illustrations/sketchy-1/error.png')}
              alt=''
              className='mw-100 mb-10 h-lg-450px'
            />
            <h1 className='fw-bold mb-10' style={{ color: '#A3A3C7' }}>
              Something went wrong!
            </h1>
            <button
              className='btn btn-primary'
              onClick={() => {
                window.location = '/';
              }}
            >
              Return Home
            </button>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}
export default ErrorBoundary;
