import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import UtilContextProvider from './contexts/utilContext';
import MenuContextProvider from './contexts/menuContext';
import UserContextProvider from './contexts/userContexts';
import { ThemeModeProvider } from './layout/partials';
import SysConfigContextProvider from './contexts/sysConfigContext';
import './AxiosConfig';
import './resources';

import 'nouislider/dist/nouislider.css';
import './theme/assets/sass/plugins.scss';
import './theme/assets/sass/style.react.scss';

const root = ReactDOM.createRoot(document.getElementById('root'));

window.addEventListener('storage', function (event) {
  if (event.key === 'user' && !event.newValue) {
    window.location.reload();
  }
});

root.render(
  <ThemeModeProvider>
    <BrowserRouter>
      <UtilContextProvider>
        <SysConfigContextProvider>
          <MenuContextProvider>
            <UserContextProvider>
              <App />
            </UserContextProvider>
          </MenuContextProvider>
        </SysConfigContextProvider>
      </UtilContextProvider>
    </BrowserRouter>
  </ThemeModeProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
