Object.defineProperty(exports, '__esModule', {value: true});
exports.EventHandlerUtil = void 0;
var _DataUtil_1 = require('./_DataUtil');
var _TypesHelpers_1 = require('./_TypesHelpers');
var EventHandlerUtil = /** @class */ (function () {
  function EventHandlerUtil() {}
  EventHandlerUtil.setEventMetasByName = function (name, handlers) {
    EventHandlerUtil.store[name] = handlers;
  };
  EventHandlerUtil.getEventMetaByName = function (name) {
    return EventHandlerUtil.store[name];
  };
  EventHandlerUtil.setEventMetaByNameAndHandlerId = function (name, handlerId, meta) {
    if (EventHandlerUtil.store[name]) {
      EventHandlerUtil.store[name][handlerId] = meta;
      return;
    }
    EventHandlerUtil.store[name] = {};
    EventHandlerUtil.store[name][handlerId] = meta;
  };
  EventHandlerUtil.getEventMetaByHandlerId = function (name, handlerId) {
    var handlersIds = EventHandlerUtil.store[name];
    if (!handlersIds) {
      return;
    }
    return handlersIds[handlerId];
  };
  EventHandlerUtil.setFiredByNameAndHandlerId = function (name, handerId, fired) {
    var meta = EventHandlerUtil.getEventMetaByHandlerId(name, handerId);
    if (!meta) {
      return;
    }
    meta.fired = fired;
    EventHandlerUtil.setEventMetaByNameAndHandlerId(name, handerId, meta);
  };
  EventHandlerUtil.addEvent = function (element, name, callback, one) {
    if (one === void 0) {
      one = false;
    }
    var handlerId = (0, _TypesHelpers_1.getUniqueIdWithPrefix)('event');
    var data = _DataUtil_1.DataUtil.get(element, name);
    var handlersIds = data ? data : [];
    handlersIds.push(handlerId);
    _DataUtil_1.DataUtil.set(element, name, handlersIds);
    var meta = {
      name: name,
      callback: callback,
      one: one,
      fired: false,
    };
    EventHandlerUtil.setEventMetaByNameAndHandlerId(name, handlerId, meta);
    return handlerId;
  };
  EventHandlerUtil.removeEvent = function (element, name, handerId) {
    _DataUtil_1.DataUtil.removeOne(element, name, handerId);
    var handlersIds = EventHandlerUtil.store[name];
    if (handlersIds) {
      return;
    }
    delete EventHandlerUtil.store[name][handerId];
  };
  EventHandlerUtil.trigger = function (element, name, target, e) {
    var returnValue = true;
    if (!_DataUtil_1.DataUtil.has(element, name)) {
      return returnValue;
    }
    var eventValue;
    var handlerId;
    var data = _DataUtil_1.DataUtil.get(element, name);
    var handlersIds = data ? data : [];
    for (var i = 0; i < handlersIds.length; i++) {
      handlerId = handlersIds[i];
      if (EventHandlerUtil.store[name] && EventHandlerUtil.store[name][handlerId]) {
        var handler = EventHandlerUtil.store[name][handlerId];
        if (handler.name === name) {
          if (handler.one) {
            if (handler.fired) {
              EventHandlerUtil.store[name][handlerId].fired = true;
              eventValue = handler.callback.call(this, target);
            }
          } else {
            eventValue = handler.callback.call(this, target);
          }
          if (eventValue === false) {
            returnValue = false;
          }
        }
      }
    }
    return returnValue;
  };
  EventHandlerUtil.one = function (element, name, callBack) {
    EventHandlerUtil.addEvent(element, name, callBack, true);
  };
  EventHandlerUtil.off = function (element, name, handerId) {
    EventHandlerUtil.removeEvent(element, name, handerId);
  };
  EventHandlerUtil.store = {};
  EventHandlerUtil.on = function (element, name, callBack) {
    EventHandlerUtil.addEvent(element, name, callBack, false);
  };
  return EventHandlerUtil;
})();
exports.EventHandlerUtil = EventHandlerUtil;
