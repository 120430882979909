import jwtDecode from 'jwt-decode';
import moment from 'moment';

export const IdentityHelper = {
  isTokenExpired(decodedToken) {
    return moment(decodedToken.exp * 1000).diff(moment().add(moment.duration(ServerTime.serverTimeDiff, "seconds")), "seconds") <= 0
  },
  isTokenValid(token = localStorage.getItem('user')) {
    try {
      let decoded = jwtDecode(token);
      return !this.isTokenExpired(decoded);
    } catch (e) {
      return false;
    }
  },
  shouldRefresh(token = this.token) {
    try {
      let decoded = jwtDecode(token);
      let span = moment(decoded.exp * 1000).diff(moment().add(moment.duration(ServerTime.serverTimeDiff, "seconds")), "seconds");
      return span <= (15 * 60)
    } catch (e) {
      return false;
    }
  },
  get UserData() {
    return this.isTokenValid() ? jwtDecode(this.token) : null;
  },

  get isAuthorized() {
    return this.isTokenValid() ? true : false;
  },

  get token() {
    if (this.isTokenValid()) {
      return localStorage.getItem('user');
    } else {
      return null;
    }
  },
  set token(val) {
    if (this.isTokenValid(val)) {
      localStorage.setItem('user', val);
    } else {
      localStorage.removeItem('user');
    }
  },
  removeToken: () => {
    localStorage.removeItem('user');
  },
  get failedAttemptCount() {
    return localStorage.getItem('failed-attempt-count')
      ? parseInt(localStorage.getItem('failed-attempt-count'))
      : 0;
  },
  get failedAttemptTime() {
    return localStorage.getItem('failed-attempt-time');
  },
  set failedAttemptCount(val) {
    localStorage.setItem('failed-attempt-count', val);
  },
  addFailedAttempt: () => {
    var newFailedAttemptCount =
      (localStorage.getItem('failed-attempt-count')
        ? parseInt(localStorage.getItem('failed-attempt-count'))
        : 0) + 1;
    localStorage.setItem('failed-attempt-count', newFailedAttemptCount);
    localStorage.setItem(
      'failed-attempt-time',
      new Date(new Date().getTime() + 5 * 60000).getTime()
    );
  },
  removeFailedAttempt: () => {
    localStorage.removeItem('failed-attempt-count');
    localStorage.removeItem('failed-attempt-time');
  },
};

export const CultureHelper = {
  isLanguageSupported(lang = localStorage.getItem('i18nextLng')) {
    if (lang === 'ar' || lang === 'en') {
      return true;
    } else {
      return false;
    }
  },
  get language() {
    if (this.isLanguageSupported()) {
      return localStorage.getItem('i18nextLng');
    } else {
      return null;
    }
  },
  set language(val) {
    if (this.isLanguageSupported(val)) {
      localStorage.setItem('i18nextLng', val);
    } else {
      localStorage.setItem(
        'i18nextLng',
        process.env.REACT_APP_DEFAULT_LANGIAGE
      );
    }
  },
  resetLanguage() {
    this.language = process.env.REACT_APP_DEFAULT_LANGIAGE;
  },
};

export const ThemeModeHelper = {
  get themeModeValue() {
    return localStorage.getItem('kt_theme_mode_value');
  },
  set themeModeValue(val) {
    localStorage.setItem('kt_theme_mode_value', val);
  },
  get themeModeMenu() {
    return localStorage.getItem('kt_theme_mode_value');
  },
  set themeModeMenu(val) {
    localStorage.setItem('kt_theme_mode_value', val);
  },
};
export const ServerTime = {
  get serverTimeDiff() {
    return localStorage.getItem('serverTime');
  },
  set serverTimeDiff(val) {
    localStorage.setItem('serverTime', val);
  },
};