import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import translationEN from "./translationEN.json";
import translationAR from "./translationAR.json";
import { CultureHelper } from "../common/utils/utils";

if (!CultureHelper.language) {
  CultureHelper.language = process.env.REACT_APP_DEFAULT_LANGIAGE
}

const resources = {
  en: {
    translation: translationEN,
  },
  ar:{
    translation: translationAR,

  }
};

i18n.use(LanguageDetector).use(initReactI18next).init({ resources });
