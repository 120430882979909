import { lazy, useContext, useEffect } from 'react';
import { useRoutes } from 'react-router-dom';
import './App.css';
import Loadable from './components/common/loadable/loadable';
// import { initDirection } from './common/utils/utils';
import ErrorBoundary from './components/errorBoundary';
import { MenuContext } from './contexts/menuContext';
import { LayoutProvider } from './layout/core';
import { MasterInit } from './layout/MasterLayout/MasterInit';
import StyleFileHandler from './theme/helpers/components/styleFileHandler';
const SSOiFrame = Loadable(lazy(() => import('./views/sso/ssoFrame')));
const ExternalIdentityProvider = Loadable(
  lazy(() => import('./views/sso/externalIdentityProvider'))
);
const SignOut = Loadable(lazy(() => import('./views/sso/signOut')));

function App() {
  const { ActiveRoutes } = useContext(MenuContext);

  const Routes = useRoutes([
    ActiveRoutes,
    {
      path: '/navigator',
      element: <SSOiFrame />,
    },
    {
      path: '/identity',
      element: <ExternalIdentityProvider />,
    },
    {
      path: '/sign-out',
      element: <SignOut />,
    },
  ]);

  return (
    <StyleFileHandler>
      <LayoutProvider>
        <ErrorBoundary>{Routes}</ErrorBoundary>
        <MasterInit />
      </LayoutProvider>
    </StyleFileHandler>
  );
}

export default App;
