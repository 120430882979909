import Loadable from '../../components/common/loadable/loadable';
import AuthLayout from '../../layout/authLayout';
import { lazy } from 'react';
import { Navigate } from 'react-router-dom';

const AuthLogin = Loadable(lazy(() => import('../../views/security/login')));

const AuthRoutes = {
  path: '/',
  element: <AuthLayout />,
  children: [
    {
      path: '/',
      element: <AuthLogin />,
    },
    {
      path: '*',
      element: <Navigate to='/' replace />,
    },
  ],
};

export default AuthRoutes;
