import moment from 'moment';
import { createContext, useState, useEffect, useContext, lazy } from 'react';
import { ServerTime } from '../../common/utils/utils';
import { UtilContext } from '../utilContext';
import { GetServerTime, GetSystemConfiguration } from './service';
export const SysConfigContext = createContext();
const SysConfigContextProvider = (props) => {
    const { ShowError, ShowLoader } = useContext(UtilContext);
    const [configInfo, setConfigInfo] = useState();
    const fetchConfigs = async () => {
        ShowLoader(true);
        await GetServerTime()((data) => {ServerTime.serverTimeDiff = moment().diff(moment(data), "seconds") }, ShowError)
        await GetSystemConfiguration()((data) => setConfigInfo(data), ShowError);
        ShowLoader(false);
    }
    useEffect(() => {
        fetchConfigs();
    }, []);

    return <>
        <SysConfigContext.Provider value={{ configInfo }}>
            {props.children}
        </SysConfigContext.Provider>
    </>
}
export default SysConfigContextProvider;