import axios from 'axios';

export const UserSearchnullObject = Object.freeze({
    intActive: null,
    intLocked: null,
    intGroupId: null,
    strMobileNo: null,
    strUserName: null,
    strEmail: null,
    strFirstName: null,
    strSecondName: null,
    strThirdName: null,
    strFamilyName: null,
})


function formatObject(obj, add) {
    obj.intActive = obj.intActive !== "" ? Number(obj.intActive) : null;
    obj.intLocked = obj.intLocked !== "" ? Number(obj.intLocked) : null;
    obj.intUpdatePassword = obj.intUpdatePassword !== "" ? Number(obj.intUpdatePassword) : null;
    obj.intGroupId = obj.intGroupId > 0 ? Number(obj.intGroupId) : null;
    obj.intDefaultSystem = obj.intDefaultSystem > 0 ? Number(obj.intDefaultSystem) : null;
    obj.strMobileNo = obj.strMobileNo.toString().trim() ? obj.strMobileNo.toString().trim() : null;
    obj.strUserName = obj.strUserName.toString().trim() ? obj.strUserName.toString().trim() : null;
    obj.strEmail = obj.strEmail.toString().trim() ? obj.strEmail.toString().trim() : null;
    obj.strFirstName = obj.strFirstName.toString().trim() ? obj.strFirstName.toString().trim() : null;
    obj.strSecondName = obj.strSecondName.toString().trim() ? obj.strSecondName.toString().trim() : null;
    obj.strThirdName = obj.strThirdName.toString().trim() ? obj.strThirdName.toString().trim() : null;
    obj.strFamilyName = obj.strFamilyName.toString().trim() ? obj.strFamilyName.toString().trim() : null;
    obj.strImagePath = obj.strImagePath ? obj.strImagePath.toString().trim() : null;
    if (add) {
        obj.strPassword = obj.strUserName
    }
    Object.keys(obj).forEach((key) => {
        if (typeof obj[key] === 'string') {
            obj[key] = obj[key].trim();
        }
    })
}


export const GetAll = () => async (onSuccess, onFail) => {
    const response = await axios.post('Users/GetUserInfoSearh', UserSearchnullObject);
    if (response.code === 1) {
        onSuccess(response.obj);
    } else {
        onFail(response.strMessage);
    }
};

export const GetLookUp = () => async (onSuccess, onFail) => {
    const response = await axios.get('Users/GetLookUp');
    if (response.code === 1) {
        onSuccess(response.obj);
    } else {
        onFail(response.strMessage);
    }
};

export const GetUserInfo = () => async (onSuccess, onFail) => {
    const response = await axios.get('Users/GetUserInfo');
    if (response.code === 1) {
        onSuccess(response.obj);
    } else {
        onFail(response.strMessage);
    }
};

export const GetByPK = (intId) => async (onSuccess, onFail) => {
    const response = await axios.get(`Users/${intId}`);
    if (response.code === 1) {
        onSuccess(response.obj);
    } else {
        onFail(response.strMessage);
    }
};

export const Insert = (image, objUsersData) => async (onSuccess, onFail) => {
    const data = new FormData();
    data.set("image", image);
    formatObject(objUsersData, true);
    data.set("UsersData", JSON.stringify(objUsersData))

    const response = await axios.post('Users', data, { 'Content-Type': 'multipart/form-data' });
    if (response.code === 1) {
        await GetAll()(onSuccess, onFail);
    } else {
        onFail(response.strMessage);
    }
};

export const Update = (image, objUsersData, deleteImage) => async (onSuccess, onFail) => {
    const data = new FormData();
    data.set("image", image);
    formatObject(objUsersData);
    data.set("UsersData", JSON.stringify(objUsersData))
    if (deleteImage) {
        data.set("deleteImage", JSON.stringify(true));
    } else {
        data.set("deleteImage", JSON.stringify(false));
    }
    let response = await axios.put('Users/', data, { 'Content-Type': 'multipart/form-data' });
    if (response.code === 1) {
        await GetAll()(onSuccess, onFail);
    } else {
        onFail(response.strMessage);
    }
};

export const Delete = (objUsersData) => async (onSuccess, onFail) => {
    objUsersData.intActive = Number(objUsersData.intActive);
    objUsersData.intGroupId = Number(objUsersData.intGroupId);
    const response = await axios.delete(`Users/Delete`, { data: objUsersData });
    if (response.code === 1) {
        await GetAll()(onSuccess, onFail);
    } else {
        onFail(response.strMessage);
    }
};
export const DeleteSelected = (lstObjUserData) => async (onSuccess, onFail) => {
    for (let i = 0; i < lstObjUserData.length - 1; i++) {
        formatObject(lstObjUserData[i]);
    }
    const response = await axios.delete(`Users/DeleteSelected`, { data: lstObjUserData });
    if (response.code === 1) {
        await onSuccess();
    } else {
        onFail(response.strMessage);
    }
};

export const ChangePassword = (oldPassword, newPassword, userInfo) => async (onSuccess, onFail) => {
    let payloadObj = {};
    payloadObj.dtmLastUpdateDate = userInfo.dtmLastUpdateDate;
    payloadObj.strPassword = oldPassword;
    payloadObj.strNewPassword = newPassword;
    const response = await axios.put("Users/ChangePassword", payloadObj);
    if (response.code == 1) {
        if (onSuccess) {
            onSuccess(response.code);
        } else {
            return response.code;
        }
    } else {
        onFail(response.strMessage);
    }
}
export const ForceChangePassword = (oldPassword, newPassword, userInfo) => async (onSuccess, onFail) => {
    let payloadObj = {};
    payloadObj.dtmLastUpdateDate = JSON.parse(userInfo.dtmLastUpdateDate);
    payloadObj.strPassword = oldPassword;
    payloadObj.strNewPassword = newPassword;
    payloadObj.intUserID = Number(userInfo.nameid);
    const response = await axios.put("Users/ForceChangePassword/", payloadObj);
    if (response.code == 1) {
        if (onSuccess) {
            onSuccess(response.code);
        } else {
            return response.code;
        }
    } else {
        onFail(response.strMessage);
    }
}

export const FrogotPassword = (Otp, newPassword, userInfo) => async (onSuccess, onFail) => {
    let payloadObj = {};
    payloadObj.dtmLastUpdateDate = JSON.parse(userInfo.dtmLastUpdateDate);
    payloadObj.intOtp =  Number(Otp);
    payloadObj.strNewPassword = newPassword;
    payloadObj.strUserName = userInfo.strUserName;
    const response = await axios.put("Users/ForgotPassword/", payloadObj);
    if (response.code == 1) {
        if (onSuccess) {
            onSuccess(response.code);
        } else {
            return response.code;
        }
    } else {
        onFail(response.strMessage);
    }
}

export const GetUserProfileInfo = () => async (onSuccess, onFail) => {
    const response = await axios.get('Users/GetUserProfileInfo');
    if (response.code === 1) {
        if (onSuccess) {
            onSuccess(response.obj);
        } else {
            return response.obj;
        }
    } else {
        onFail(response.strMessage);
    }
};

export const GetUserInfoSearch = (objUsersData) => async (onSuccess, onFail) => {
    formatObject(objUsersData)
    const response = await axios.post('Users/GetUserInfoSearh', objUsersData);
    if (response.code === 1) {
        onSuccess(response.obj);
    } else {
        onFail(response.strMessage);
    }
};
export const GetGroupSystems = (groupId) => async (onSuccess, onFail) => {
    const response = await axios.get(`GroupObject/GetGroupSystems/${(groupId)}`);
    if (response.code === 1) {
        onSuccess(response.obj);
    } else {
        onFail(response.strMessage);
    }
};
export const GetUserLoginHistory = () => async (onSuccess, onFail) => {
    const response = await axios.get(`LoginHistory/GetUserLoginHistory`);
    if (response.code === 1) {
        onSuccess(response.obj);
    } else {
        onFail(response.strMessage);
    }
};
export const GetByUserId = (intUserId) => async (onSuccess, onFail) => {
    const response = await axios.get(`LoginHistory/${intUserId}`);
    if (response.code === 1) {
        onSuccess(response.obj);
    } else {
        onFail(response.strMessage);
    }
};

export const UpdateMyProfileInfo = (objUsersData) => async (onSuccess, onFail) => {
    formatObject(objUsersData);

    const response = await axios.put('Users/UpdateMyProfileInfo', objUsersData);
    if (response.code === 1) {
        await GetUserProfileInfo()(onSuccess, onFail);
    } else {
        onFail(response.strMessage);
    }
};