export const enmDateIndicator = Object.freeze({
  today: 1,
  lastWeek: 2,
  lastMonth: 3,
});

export const enmDays = Object.freeze({
  saturday: 6,
  sunday: 1,
  monday: 2,
  tuesday: 3,
  wednesday: 4,
  thursday: 5,
  friday: 7,
});
export const enmDayNamesLocalization = Object.freeze({
  [enmDays.saturday]: 'strSaturday',
  [enmDays.sunday]: 'strSunday',
  [enmDays.monday]: 'strMonday',
  [enmDays.tuesday]: 'strTuesday',
  [enmDays.wednesday]: 'strWednesday',
  [enmDays.thursday]: 'strThursday',
  [enmDays.friday]: 'strFriday',
});

export const systemCode = 1;

export const enmDateBehaviour = Object.freeze({
  today: 0,
  lastSevenDays: 1,
  lastThirtyDays: 2
})

export const enmChangePasswordType = Object.freeze({
  Normal: 1,
  Admin: 2,
  Forgot: 3
});