import axios from "axios";

export const GetSystemConfiguration = () => async (onSuccess, onFail) => {
    const response = await axios.get('SysConfig');
    if(response.code ===1 ){
        onSuccess(response.obj);
    }else{
        onFail(response.strMessage);
    }
}
export const GetServerTime = () => async (onSuccess, onFail) => {
    const response = await axios.get('SysConfig/Time');
    if (response.code === 1) {
        onSuccess(response.obj);
    } else {
        onFail(response.strMessage);
    }
}