import { createContext, useState, useEffect, useContext, lazy } from 'react';
import AuthRoutes from '../menuContext/AuthRoutes';
import MainRoutes from '../menuContext/MainRoutes';
import Loadable from '../../components/common/loadable/loadable';
import { GetMenuObjects } from './service';
import { UtilContext } from '../utilContext';
import { useLocation } from 'react-router-dom';
import { IdentityHelper } from '../../common/utils/utils';
import { systemCode } from '../../common/utils/constants';

export const MenuContext = createContext();

const MenuContextProvider = ({ children }) => {
  const { ShowError, ShowLoader } = useContext(UtilContext);

  const [drawerOpen, setDrawerOpen] = useState();
  const [MenuItems, setMenuItems] = useState([]);
  const [ActiveRoutes, setActiveRoutes] = useState([]);
  const location = useLocation();

  const [selectedMenuItem, setSelectedMenuItem] = useState({});

  const onGetMenuSuccess = (results) => {
    setMenuItems(results);

    let routes = results
      .filter(
        (item) => item.intObjectType === 3 && item.intSystemId === systemCode
      )
      .map((menuItem) => {
        const El = Loadable(
          lazy(() => import(`../../views${menuItem.strObjectPath}`))
        );
        return {
          path: menuItem.strObjectPath,
          element: <El />,
        };
      });

    // routes.push({ path: '/abdelmajeed', element: <Users /> });

    setActiveRoutes({
      ...MainRoutes,
      children: [...MainRoutes.children, ...routes],
    });
  };

  const onGetMenuItemsError = (strMessage) => {
    ShowError(strMessage);
    setActiveRoutes(MainRoutes);
  };

  const getMenuItems = async () => {
    if (IdentityHelper.isAuthorized) {
      ShowLoader(true);
      await GetMenuObjects()(onGetMenuSuccess, onGetMenuItemsError);
      ShowLoader(false);
    } else {
      setActiveRoutes(AuthRoutes);
    }
  };

  useEffect(() => {
    getMenuItems(); // eslint-disable-next-line
  }, []);

  useEffect(() => {
    let objectItem = MenuItems.find(
      (item) => item.strObjectPath === location.pathname
    );
    if (objectItem) {
      setSelectedMenuItem(objectItem);
    } else {
      setSelectedMenuItem({});
    } // eslint-disable-next-line
  }, [location, MenuItems]);

  return (
    <MenuContext.Provider
      value={{
        drawerOpen,
        setDrawerOpen,
        selectedMenuItem,
        setSelectedMenuItem,
        MenuItems,
        setMenuItems,
        ActiveRoutes,
        // identity,
      }}
    >
      {children}
    </MenuContext.Provider>
  );
};

export default MenuContextProvider;
