import { createContext, useContext, useEffect, useState } from 'react';
import { IdentityHelper } from '../../common/utils/utils';
import { GetUserInfo } from '../../views/security/users/service';
import { UtilContext } from '../utilContext';

export const UserContext = createContext();

const UserContextProvider = (props) => {
  const { ShowError, ShowLoader } = useContext(UtilContext);
  const [userInfo, setUserInfo] = useState();

  useEffect(() => {
    fetchUserInfo();
  }, []);

  const fetchUserInfo = async () => {
    if (IdentityHelper.isAuthorized) {
      ShowLoader(true);
      await GetUserInfo()(setUserInfo, ShowError);
      ShowLoader(false);
    }
  };

  return (
    <>
      <UserContext.Provider value={{ userInfo, fetchUserInfo }}>
        {props.children}
      </UserContext.Provider>
    </>
  );
};

export default UserContextProvider;
