import axios from 'axios';

export const GetMenuObjects = () => async (onSuccess, onFail) => {
  const response = await axios.get('GroupObject/GetUserScreens');
  if (response.code === 1) {
    onSuccess(response.obj);
  } else {
    onFail(response.strMessage);
  }
};
