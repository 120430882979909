import React, { lazy } from 'react';
import { CultureHelper } from '../../../common/utils/utils';
import Loadable from '../../../components/common/loadable/loadable';

const StyleFileHandler = ({ children }) => {
  const strLang =
    CultureHelper.language || process.env.REACT_APP_DEFAULT_LANGIAGE;

  //   var StyleFile = Loadable(
  //     lazy(() => import('../../helpers/components/styleFile'))
  //   );

  //   var StyleFile = Loadable(
  //     lazy(() => import('../../helpers/components/styleFileRTL'))
  //   );

  var StyleFile;

  if (strLang === 'ar')
    StyleFile = Loadable(
      lazy(() => import('../../helpers/components/styleFileRTL'))
    );
  else {
    StyleFile = Loadable(
      lazy(() => import('../../helpers/components/styleFile'))
    );

    // document
    // .getElementById('stylesFile')
    // .setAttribute('href', strLang === 'ar' ? 'style.rtl.css' : ' ');
  }

//   document.body.setAttribute('dir', strLang === 'en' ? 'ltr' : 'rtl');

  return (
    <>
      {
        // <React.Suspense fallback={<></>}>
        <StyleFile></StyleFile>
        // </React.Suspense>
      }
      {children}
    </>
  );
};

export default StyleFileHandler;
