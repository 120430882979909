import { createContext, useEffect, useReducer, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Spinner from 'react-bootstrap/Spinner';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
export const UtilContext = createContext();

const toastInitState = { severity: '', message: '' };

const toastReducer = (state, action) => {
  switch (action.type) {
    case 'SHOW_SUCCESS':
      return { severity: 'success', message: action.payload };
    case 'SHOW_ERROR':
      return { severity: 'error', message: action.payload };
    case 'SHOW_Info':
      return { severity: 'info', message: action.payload };
    default:
      throw new Error();
  }
};

const UtilContextProvider = (props) => {
  const [loading, ShowLoader] = useState(false);
  const [toastState, dispachToast] = useReducer(toastReducer, toastInitState);
  const { t } = useTranslation();

  const ShowSuccess = (message) => {
    dispachToast({ type: 'SHOW_SUCCESS', payload: message });
  };
  const ShowError = (message) => {
    dispachToast({
      type: 'SHOW_ERROR',
      payload: message || t('strUnknownErr'),
    });
  };
  const ShowInfo = (message) => {
    dispachToast({ type: 'SHOW_Info', payload: message || t('strUnknownErr') });
  };
  useEffect(() => {
    if (toastState.message !== '')
      showToast(toastState.message, toastState.severity);
  }, [toastState]);

  const showToast = (message, severity) => {
    toast[severity](message, {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'colored',
    });
  };
  return (
    <UtilContext.Provider
      value={{
        ShowSuccess,
        ShowError,
        ShowLoader,
        loading,
        ShowInfo,
      }}
    >
      <ToastContainer />
      {loading && (
        <>
          <div
            style={{
              position: 'fixed',
              width: '100%',
              height: '100%',
              zIndex: '1550',
              opacity: '0.5',
              background: 'gray',
              filter: 'alpha(opacity=50)',
            }}
          >
            <Spinner
              animation='border'
              color='red'
              style={{ marginTop: '25%', marginInlineStart: '50%' }}
            />
          </div>
        </>
      )}

      {props.children}
    </UtilContext.Provider>
  );
};

export default UtilContextProvider;
