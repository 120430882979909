import { ProgressBar } from 'react-bootstrap';

const Loader = () => (
  <div
    style={{
      position: 'fixed',
      top: '0',
      left: '0',
      zIndex: '99999',
      width: '100%',
    }}
  >
    <ProgressBar
      style={{
        height: '5px',
      }}
    >
      <ProgressBar striped animated variant='primary' now={100} />
    </ProgressBar>
  </div>
);

export default Loader;
