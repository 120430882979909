import Loadable from '../../components/common/loadable/loadable';
import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { MasterLayout } from '../../layout/MasterLayout';

const LandingPage = Loadable(lazy(() => import('../../views/landingPage')));
// const MyProfile = Loadable(lazy(() => import('../../views/myProfile')));

const MainRoutes = {
  path: '/',
  element: <MasterLayout />,
  children: [
    {
      path: '/',
      element: <LandingPage />,
    },
    // {
    //   path: '/my-profile',
    //   element: <MyProfile />,
    // },
    {
      path: '*',
      element: <Navigate to='/' replace />,
    },
  ],
};

export default MainRoutes;
